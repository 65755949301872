const user = window.CONFIGURATION.USER;
const appId = user.intercom_id;

if (user && appId) {
  window.intercomSettings = {
    app_id: appId,
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    created_at: user.created_at_timestamp,
    user_hash: user.intercom_user_hash,
    custom_launcher_selector: ".intercom-launch"
  };

  // This function is from the Intercom site so we will leave it
  // as is and disable eslint for this part.
  /* eslint-disable */
  (function() {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", w.intercomSettings);
    } else {
      var d = document;
      var i = function() {
        i.c(arguments);
      };
      i.q = [];
      i.c = function(args) {
        i.q.push(args);
      };
      w.Intercom = i;
      var l = function() {
        var s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://widget.intercom.io/widget/" + { appId };
        var x = d.getElementsByTagName("script")[0];
        x.parentNode.insertBefore(s, x);
      };
      if (w.attachEvent) {
        w.attachEvent("onload", l);
      } else {
        w.addEventListener("load", l, false);
      }
    }
  })();
  /* eslint-enable */
}
