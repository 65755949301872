const user = window.CONFIGURATION.USER;
const pendoAnalyticsAPIKey = user.pendo_analytics_api_key;

if (user && pendoAnalyticsAPIKey) {
  // This function is from the Pendo site so we will leave it
  // as is and disable eslint for this part.
  /* eslint-disable */
  (function (apiKey) {
    (function (p, e, n, d, o) {
      var v, w, x, y, z;
      o = p[d] = p[d] || {};
      o._q = [];
      v = ["initialize", "identify", "updateOptions", "pageLoad"];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          o[m] =
            o[m] ||
            function () {
              o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      y.async = !0;
      y.src = "https://content.pendo.syntellis.com/agent/static/" + apiKey + "/pendo.js";
      z = e.getElementsByTagName(n)[0];
      z.parentNode.insertBefore(y, z);
    })(window, document, "script", "pendo");

    // Call this whenever information about your visitors becomes available
    // Please use strings, numbers, or booleans for value types.
    pendo.initialize({
      visitor: {
        id: user.id, // Required if user is logged in
        email: user.email, // Optional
        role: user.type, // Optional

        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
        userSince: user.created_at_date
      }

      // account: {
      // id:           'ACCOUNT-UNIQUE-ID' // Highly recommended
      // name:         // Optional
      // planLevel:    // Optional
      // planPrice:    // Optional
      // creationDate: // Optional

      // // You can add any additional account level key-values here,
      // // as long as it's not one of the above reserved names.
      // }
    });
  })(pendoAnalyticsAPIKey);
  /* eslint-enable */
}
